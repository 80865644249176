<template>
  <LoginLayout>
    <v-container fill-height fluid>
      <v-row align="center" justify="center">
        <v-col>
          <form @submit.prevent>
            <h2 class="text-center my-4">{{ pageTitle }}</h2>
            <v-card max-width="300" class="mx-auto">
              <v-card-text>
                <div class="p-2">
                  <v-text-field
                    v-model="email"
                    type="email"
                    :error-messages="emailErrors"
                    label="Masukkan Email"
                    required
                    @input="$v.email.$touch()"
                    @blur="$v.email.$touch()"
                  ></v-text-field>
                </div>
              </v-card-text>

              <v-card-actions>
                <v-btn
                  class="mr-4"
                  @click="submit"
                  block
                  :color="$store.state.appThemeColor"
                  dark
                >
                  Kirim
                </v-btn>
              </v-card-actions>
              <v-card-actions>
                <v-btn text link :to="{ name: 'Login' }" block>Kembali ke Login</v-btn>
              </v-card-actions>
            </v-card>
          </form>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      v-model="dialogSuccess"
      persistent
      max-width="290"
    >
    <v-card>
        <v-card-title class="text-h5">
          Sukses
        </v-card-title>
        <v-card-text>Aktivasi akun berhasil, silahkan cek email Anda untuk mendapatkan informasi login.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="goTo('/login')"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </LoginLayout>
</template>

<script>
import LoginLayout from "@/layouts/LoginLayout.vue";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

export default {
  name: "ActivateAccount",
  components: { LoginLayout },
  mixins: [validationMixin],

  validations: {
    email: { required, email },
  },

  data: () => ({
    email: "",
    pageTitle: "Aktivasi Akun",
    dialogSuccess: null,
  }),

  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.required && errors.push("Email tidak boleh kosong.");
      !this.$v.email.email && errors.push("Email tidak valid.");
      return errors;
    },
  },

  methods: {
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let formData = new FormData();
        formData.append("email", this.email);
        formData.append("user_type", 'student_parents');

        this.$store.state.showLoadingDialog = true
        this.$axios.post("activateaccount/validation", formData).then(res => {
          this.$store.state.showLoadingDialog = false
          if (res.data.status == "success") {
            this.dialogSuccess = true
          } else {
            this.showAlert(res.data.status, res.data.message)
          }
        })
        .catch(error => {
          console.log(error)
          this.showAlert('error', 'Internal Server Error!')
          this.$store.state.showLoadingDialog = false
        })
        .finally(() => (this.$store.state.showLoadingDialog = false))

      }
    },
    clear() {
      this.$v.$reset();
      this.email = "";
    },
  },
};
</script>


<style scoped>
.bg1 {
  background: #0F2027;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #2C5364, #203A43, #0F2027);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #2C5364, #203A43, #0F2027); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
h2 {
  color: #ffffff;
}
</style>